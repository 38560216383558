<template>
  <div>
    <b-row>
      <b-col lg="8" class="content-d">
        <p class="content-slogan w-100 m-0">{{ $t('search-station-smartbus') }}</p>
        <p>{{ $t('search-station-smartbus-content') }}</p>
        <p><strong class="mr-2">{{ $t('step1') }}</strong>{{ $t('station-search-1') }}</p>
        <div class="text-center">
          <img class="mx-auto" src="/assets/image/guide/guide_1.png">
        </div>
        <p class="mb-0"><strong class="mr-2">{{ $t('step2') }}</strong>{{ $t('station-search-2') }}</p>
        <p>{{ $t('station-search-2-1') }}</p>
        <div class="text-center">
          <img src="/assets/image/guide/guide_2.jpg">
        </div>
        <p class="mb"><strong class="mr-2">{{ $t('web') }}:</strong>{{ $t('station-manual') }}</p>
        <div class="text-center">
          <img src="/assets/image/guide/guide_21.jpg">
        </div>
        <p>{{ $t('station-search-2-2') }}</p>
        <p>{{ $t('station-search-2-3') }}</p>
      </b-col>
      <b-col lg="4">
        <RightMenu />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import RightMenu from "@/layout/RightMenu";
export default {
  components: {
    RightMenu,
  },
};
</script>

<style scoped>
.content-d p {
  font-size: 16px;
}
.content-d img{
  margin-bottom: 40px;
}
.font-64 {
  font-size: 64px;
}
.font-24 {
  font-size: 24px;
}
.title-block {
  font-size: 12px;
  font-weight: 300;
  color: #636466;
  width: 75%;
}
.title-secondary {
  font-size: 16px;
  font-weight: 300;
  color: #636466;
  width: 75%;
  margin-bottom: 40px !important;
}
.content-slogan {
  color: #c71313;
  font-size: 48px !important;
  width: 50%;
  margin: 16px 0 !important;
}
.content-card {
  border: 1px solid #e6e8ec;
  border-radius: 24px;
  overflow: hidden;
  transition: 500ms;
  cursor: pointer;
}
.content-card p {
  height: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.content-card:hover {
  box-shadow: 0px 40px 32px -24px rgba(15, 15, 15, 0.12);
}
</style>
